import { CircularProgress } from '@material-ui/core'
import { memo, Suspense } from 'react'

function SuspenseLoader({ children, style }) {
    return (
        <Suspense fallback={
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', ...style }}>
                <CircularProgress />
                <h1>&nbsp;&nbsp;Loading...</h1>
            </div >
        }>
            {children}
        </Suspense >
    )
}

export default memo(SuspenseLoader)
