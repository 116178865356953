import { createTheme, ThemeProvider } from '@material-ui/core';
import { lazy, memo } from 'react';
import { Route, Switch, Redirect, HashRouter } from 'react-router-dom';
import SuspenseLoader from './Components/SuspenseLoader';
import { SnackbarProvider } from 'notistack';


const Homepage = lazy(() => import('./Pages/Homepage/Homepage'));
// const Form = lazy(() => import('./Pages/quiz/Form'));
const Result = lazy(() => import('./Pages/Results/Result'));
// const Venue = lazy(() => import('./Pages/Venue/Venue'));

const darkTheme = createTheme({
  palette: {
    type: 'dark',
    background: {
      paper: '#202020',
    },
    primary: {
      main: "#0288d1",
    },
  },
});

function App() {
  return (
    <HashRouter>
      <ThemeProvider theme={darkTheme}>
        <SuspenseLoader style={{ minHeight: '100vh', width: '100vw' }}>
          <SnackbarProvider
            maxSnack={1}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <Switch>
              {/* <Route path="/venue" component={Venue} exact /> */}
              {/* <Route path="/form" component={Form} exact /> */}
              <Route path="/sTQjKRc7ZverD7cSY4NRynW9Xa56ysSYG9C8umPA5HqUeHcuvMMEj7kg3KmcJMXHHZqCadChYhyAneCTZsHkVfPnEea8nfrN39vtTnzEm8D8EhnZKw4mzVENUyQVE54Z" component={Result} exact />
              <Route path="/" component={Homepage} exact />
              <Redirect to="/" />
            </Switch>
          </SnackbarProvider>
        </SuspenseLoader>
      </ThemeProvider>
    </HashRouter>
  );
}

export default memo(App);

